import Vue from 'vue';
import IdleVue from 'idle-vue';
import App from './App.vue';
import store from '@/store/index';
import router from './router/index';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/style/base.scss';
import '@/style/elementUI.css';
import animate from 'animate.css';
import moment from 'moment';
import mixins from '@/util/mixins';
import LabelSpan from '@/components/LabelSpan';
import SelectButton from '@/components/buttons/SelectButton';
import Print from 'vue-print-nb';
import htmlToPdf from '@/util/htmlToPdfUtil.js';
import { getColumnSetting, getDicts, batchGetDicts, upInsertColumnSetting } from '@/service/system/dict/data.js';
import { resetForm, addDateRange, getDictLabel, getDictLabels, downloadFile, propIsDisabled } from '@/util/duola';

// import {mockXHR} from "@/mock/index";

// mockXHR();
//顶部横向导航
router.beforeEach(({ name, path, query, params, meta }, from, next) => {
  next();
  const tmpItem = { name, path, query, params, meta };
  if (name && name != '403' && name != 'login' && !meta.isNotTab) {
    let tmpList = JSON.parse(localStorage.getItem('tmpTabsList') || '[]');
    if (tmpList.length && name != 'login' && tmpList.some((v) => JSON.stringify(v) == JSON.stringify(tmpItem))) {
      return;
    }
    // 跳转至相同的携带参数的路由页面处理
    tmpList.forEach((v, i) => {
      if (v.name == name && v.path == path) {
        tmpList.splice(i, 1);
      }
    });

    localStorage.setItem('tmpTabsList', JSON.stringify([...tmpList, tmpItem]));
  }
});

Vue.prototype.getDicts = getDicts;
Vue.prototype.batchGetDicts = batchGetDicts;
Vue.prototype.getColumnSetting = getColumnSetting;
Vue.prototype.upInsertColumnSetting = upInsertColumnSetting;
Vue.prototype.resetForm = resetForm;
Vue.prototype.addDateRange = addDateRange;
Vue.prototype.getDictLabel = getDictLabel;
Vue.prototype.getDictLabels = getDictLabels;
Vue.prototype.downloadFile = downloadFile;
Vue.prototype.propIsDisabled = propIsDisabled;

Vue.use(mixins);
Vue.use(ElementUI);
Vue.use(animate);
Vue.use(Print);
Vue.use(htmlToPdf);
Vue.config.productionTip = false;
Vue.prototype.$moment = moment;
Vue.component('label-span', LabelSpan);
Vue.component('select-button', SelectButton);
Vue.use(() => (Vue.prototype.$bus = new Vue()));

const eventsHub = new Vue({
  store,
  router,
  render: (h) => h(App)
}).$mount('#app');

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 3000,
  startAtIdle: false
});

export default eventsHub;
